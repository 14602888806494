import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapp-home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_Army = _resolveComponent("Army")!
  const _component_Token = _resolveComponent("Token")!
  const _component_Score = _resolveComponent("Score")!
  const _component_War = _resolveComponent("War")!
  const _component_Community = _resolveComponent("Community")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_Banner),
    _createVNode(_component_Army),
    _createVNode(_component_Token),
    _createVNode(_component_Score),
    _createVNode(_component_War),
    _createVNode(_component_Community)
  ]))
}