
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Banner',
  data() {
    return {
      active: true
    }
  },

  beforeUnmount(): void {
    this.active = false
  }
})
