
import { defineComponent } from 'vue'

import Banner from './components/banner.vue'
import Army from './components/army.vue'
import Token from './components/token.vue'
import Score from './components/score.vue'
import War from './components/war.vue'
import Community from './components/community.vue'

export default defineComponent({
  name: "Home",
  components: { Banner, Army, Token, Score, War, Community }
})
